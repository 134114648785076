import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

import { CustomerStore } from '@lib-core/stores';
import { CustomerResource } from '@lib-core/resources';
import { ApiResponseModel, CustomerModel } from '@lib-core/models';

@Injectable({
	providedIn: 'root',
})
export class CustomerService {
	constructor(private customerResource: CustomerResource, private customerStore: CustomerStore) {}

	listCustomersByRepresentative(representativeCode: string): Observable<ApiResponseModel<CustomerModel[]>> {
		return this.customerResource.listCustomersByRepresentative(representativeCode);
	}
	
	getCustomerByCode(customerCode: string): Observable<ApiResponseModel<CustomerModel[]>> {
		return this.customerResource.GetCustomerByCode(customerCode);
	}

	getCurrentCustomer() {
		return of(this.customerStore.get());
	}
}
