import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { AuthService } from '@lib-auth/auth/auth.service';
import { AUTH_ROUTE_NAMES } from '@lib-auth/auth/auth.constants';
import { LoaderService, RouterService } from '@lib-core/services';

const DEFAULT_TIMEOUT = 1;
const DEFAULT_STEP = 1000;

@Component({
	standalone: true,
	selector: 'auth-logout',
	imports: [CommonModule, RouterLink],
	templateUrl: './logout.component.html',
})
export class LogoutComponent implements OnInit, OnDestroy {
	decrementTimeout: any;

	timeout = DEFAULT_TIMEOUT;
	authRoutes = AUTH_ROUTE_NAMES;

	constructor(private authService: AuthService, private routerService: RouterService, private loaderService: LoaderService) {}

	ngOnInit(): void {
		this.decrement();
		this.loaderService.start();
	}

	ngOnDestroy(): void {
		this.clear();
	}

	cancel(): void {
		this.routerService.goBack();
	}

	logout(): void {
		this.clear();
		this.loaderService.start();
		this.authService.logout();
	}

	decrement(): void {
		this.timeout--;
		if (this.timeout === 0) {
			this.logout();
			return;
		}

		this.decrementTimeout = setTimeout(() => {
			this.decrement();
		}, DEFAULT_STEP);
	}

	clear(): void {
		clearTimeout(this.decrementTimeout);
	}
}
