export const AUTH_DATA_COOKIE_NAME = 'auth-data';
export const AUTH_REMEMBER_ME_COOKIE_NAME = 'last-login-details';

export const THEME_COOKIE_NAME = 'theme';
export const USER_DATA_COOKIE_NAME = 'user-data';

export const SIDE_FILTER_COOKIE_NAME = 'side-filter';
export const GALLERY_DATA_COOKIE_NAME = 'gallery-data';
export const GALLERY_REQUEST_COOKIE_NAME = 'gallery-request';
export const GALLERY_PRODUCT_SCROLL_POSITION_COOKIE_NAME = 'scroll-position';

export const EBOOK_IMPORT_COOKIE_NAME = 'ebook-import';

export const CUSTOMER_COOKIE_NAME = 'customer';
export const CUSTOMER_LIST_COOKIE_NAME = 'customer-list';

export const CART_LIST_COOKIE_NAME = 'cart-list';
export const CART_COUNT_COOKIE_NAME = 'cart-count';

export const MODALITY_COOKIE_NAME = 'modality';
export const MODALITY_LIST_COOKIE_NAME = 'modality-list';

export const SALES_TEAM_COOKIE_NAME = 'sales-team';
export const SALES_TEAM_LIST_COOKIE_NAME = 'sales-team-list';

export const SALES_PERIOD_COOKIE_NAME = 'sales-period';
export const SALES_PERIOD_LIST_COOKIE_NAME = 'sales-period-list';

export const REPRESENTATIVE_COOKIE_NAME = 'sales-representative';
export const REPRESENTATIVE_LIST_COOKIE_NAME = 'sales-representative-list';

export const SESSION_COOKIE_LIST = [
	AUTH_DATA_COOKIE_NAME,
	USER_DATA_COOKIE_NAME,
	EBOOK_IMPORT_COOKIE_NAME,
	CUSTOMER_COOKIE_NAME,
	CUSTOMER_LIST_COOKIE_NAME,
	CART_LIST_COOKIE_NAME,
	CART_COUNT_COOKIE_NAME,
	MODALITY_COOKIE_NAME,
	MODALITY_LIST_COOKIE_NAME,
	SALES_TEAM_COOKIE_NAME,
	SALES_TEAM_LIST_COOKIE_NAME,
	SALES_PERIOD_COOKIE_NAME,
	SALES_PERIOD_LIST_COOKIE_NAME,
	REPRESENTATIVE_COOKIE_NAME,
	REPRESENTATIVE_LIST_COOKIE_NAME,
	SIDE_FILTER_COOKIE_NAME,
	GALLERY_DATA_COOKIE_NAME,
	GALLERY_REQUEST_COOKIE_NAME,
];
