import { CommonModule, DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject } from '@angular/core';
import { DEFAULT_TIMEOUT, LoaderService } from '@lib-core/services';

@Component({
	standalone: true,
	selector: 'app-loader',
	imports: [CommonModule],
	styleUrl: './loader.component.scss',
	templateUrl: './loader.component.html',
})
export class LoaderComponent implements AfterViewInit {
	isDelaying = false;
	loaderMessage = '';

	constructor(
		@Inject(DOCUMENT)
		private document: Document,
		public loaderService: LoaderService
	) {}

	ngOnInit() {
		this.loaderService.isLoading$.subscribe((isLoading) => {
			if (isLoading) {
				this.document.body.classList.add('is-loading');
			} else {
				this.document.body.classList.remove('is-loading');
			}
		});

		this.loaderService.loadingMessage$.subscribe((message) => {
			this.loaderMessage = message;
		});
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.isDelaying = true;
		}, DEFAULT_TIMEOUT);
	}
}
