<div class="accordion" [class]="classList">
	<div class="accordion-item">
		<h2 class="accordion-header">
			<div class="accordion-button">
				<ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
			</div>
		</h2>
		<div class="accordion-collapse collapse show">
			<div class="accordion-body" [class]="bodyClassList">
				<ng-container *ngTemplateOutlet="bodyTemplate"></ng-container>
			</div>
		</div>
	</div>
</div>
