import { NgTemplateOutlet } from '@angular/common';
import { Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
	standalone: true,
	selector: 'core-group-box, [core-group-box]',
	imports: [NgTemplateOutlet],
	templateUrl: './group-box.component.html',
	styles: [
		`
			.accordion-button::after {
				visibility: hidden;
			}
		`,
	],
})
export class GroupBoxComponent {
	@ContentChild('header')
	headerTemplate: TemplateRef<any>;

	@ContentChild('body')
	bodyTemplate: TemplateRef<any>;

	@Input()
	classList: string;

	@Input()
	bodyClassList: string;
}
