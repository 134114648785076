import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgbModal, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormsModule, ReactiveFormsModule, AbstractControl } from '@angular/forms';

import {
	CustomerModel,
	RepresentativeModel,
	SalesReturnProcessoModel,
	SalesReturnProcessoAnexoModel,
	SalesReturnProcessoListaModel,
	SalesReturnProcessoListaItemModel,
} from '@lib-core/models';
import { isStringNullOrEmpty } from '@lib-core/helpers';

import { CustomerStore, RepresentativeStore } from '@lib-core/stores';
import { RouterService, SalesOrderService, SalesReturnService, ToastService } from '@lib-core/services';
import { RequesterType, SalesReturnFieldIdType, SalesReturnStepType, SalesReturnType } from '@lib-core/enums';

import { ACCOUNT_RETURN_ROUTE_NAMES } from '@lib-shared/modules/account/account.constants';
import { AccountSalesReturnItemsComponent } from '@lib-shared/modules/account/account-sales-return/components/account-sales-return-items/account-sales-return-items.component';
import { AccountSalesReturnInvoicesComponent } from '@lib-shared/modules/account/account-sales-return/components/account-sales-return-invoices/account-sales-return-invoices.component';
import { AccountSalesReturnAttachmentsComponent } from '@lib-shared/modules/account/account-sales-return/components/account-sales-return-attachments/account-sales-return-attachments.component';

@Component({
	standalone: true,
	selector: 'shared-account-sales-return-form',
	imports: [
		FormsModule,
		CommonModule,
		NgbTooltipModule,
		ReactiveFormsModule,
		AccountSalesReturnItemsComponent,
		AccountSalesReturnInvoicesComponent,
		AccountSalesReturnAttachmentsComponent,
	],
	templateUrl: './account-sales-return-form.component.html',
})
export class AccountSalesReturnFormComponent implements OnInit {
	success = false;
	submitted = false;
	itemListId: number;
	invoiceListId: number;
	successMessage: string;

	customer: CustomerModel;
	reasonList: any;
	requesterList: any;

	processForm!: FormGroup;
	representative: RepresentativeModel;

	@Input()
	fluxo: SalesReturnType;

	@Input()
	etapa: SalesReturnStepType;

	@Input()
	isActive: boolean;

	@Input()
	processo: SalesReturnProcessoModel;

	@ViewChild('successModal')
	successModal: ElementRef;

	constructor(
		private fb: FormBuilder,
		private router: Router,
		private modalService: NgbModal,
		private toastService: ToastService,
		private routerService: RouterService,
		private customerStore: CustomerStore,
		private salesOrderService: SalesOrderService,
		private salesReturnService: SalesReturnService,
		private representativeStore: RepresentativeStore,
	) {
		this.customer = this.customerStore.get();
		this.representative = this.representativeStore.get();
	}

	ngOnInit(): void {
		this.itemListId = this.salesReturnService.getItemListId(this.fluxo);
		this.invoiceListId = this.salesReturnService.getInvoiceListId(this.fluxo);
		this.reasonList = this.salesReturnService.getReasonList(this.fluxo);
		this.requesterList = this.salesReturnService.getRequesterList();
		this.registerForm();
	}

	get f() {
		return this.processForm.controls;
	}

	get isInactive(): boolean {
		return !this.isActive;
	}

	get itemList(): SalesReturnProcessoListaModel[] {
		return this.processForm?.value.items ?? [];
	}

	get attachmentList(): SalesReturnProcessoAnexoModel[] {
		return this.processForm?.value.attachments ?? [];
	}

	get invoiceList(): SalesReturnProcessoListaModel[] {
		return this.processForm?.value.invoices ?? [];
	}

	get enableInvoices(): boolean {
		return this.processForm?.value.reason == 2 || this.fluxo == SalesReturnType.SixOrMore || this.processo?.motivo == 2;
	}

	get isInvalid(): boolean {
		return this.processo != null && this.processo?.processo_id == null;
	}

	get isReadonly(): boolean {
		return !this.salesReturnService.isRequestStep(this.etapa, this.fluxo) || !this.isActive;
	}

	get isProofOfDiscard(): boolean {
		return this.salesReturnService.isProofOfDiscardStep(this.etapa, this.fluxo);
	}

	get isRepresentative(): boolean {
		return !isStringNullOrEmpty(this.representative?.code);
	}

	get isItemsInvalidForProofOfDiscard(): boolean {
		if (!this.isProofOfDiscard) {
			return false;
		}

		// existe um bug que deixa as listas com um item em branco, então filtramos para pegar apenas os itens com material informado
		const validList = this.itemList.flatMap(i => i.lista).filter(i => !!i.find(e => e.id == SalesReturnFieldIdType.Material)?.valor);

		// busca todos os itens que possuem o anexo de comprovação de descarte
		const attachmentList = validList.map(i => !!i.find(e => e.id == SalesReturnFieldIdType.AttachmentProofOfDiscard)?.valor);

		return !attachmentList.every(i => i === true);
	}

	get isItemsInvalidForProofOfDiscardTooltip(): string {
		return this.isItemsInvalidForProofOfDiscard ? 'É necessário anexar a foto da peça sem etiqueta para todos os itens antes de salvar sua solicitação.' : '';
	}

	get saveButtonLabel(): string {
		if (this.processo?.processo_id) {
			return 'Salvar solicitação';
		}
		if (this.isProofOfDiscard) {
			return 'Salvar comprovação de descarte';
		}
		return 'Criar solicitação';
	}

	minLengthArray(min: number) {
		return (c: AbstractControl): { [key: string]: any } => {
			if (c.value.length >= min) return null;

			return { minLengthArray: { valid: false } };
		};
	}

	registerForm(): void {
		this.processForm = this.fb.group({
			reason: [{ value: '', disabled: this.isReadonly }, [Validators.required]],
			requester: [
				{
					value: this.isRepresentative ? RequesterType.Representative : RequesterType.Customer,
					disabled: this.isReadonly,
				},
				[Validators.required],
			],
			notes: [{ value: '', disabled: this.isReadonly }],
			items: [[], this.fluxo == SalesReturnType.UpToFive ? [Validators.required] : [Validators.required, this.minLengthArray(6)]],
			invoices: [[]],
			attachments: [[], [Validators.required]],
		});

		this.patchProcess();
	}

	changeReason(): void {
		this.processForm.patchValue({
			invoices: [],
		});
		this.updateInvoicesValidators();
	}

	updateInvoicesValidators() {
		switch (this.enableInvoices) {
			case true:
				this.f.invoices.setValidators(Validators.required);
				break;
			case false:
				this.f.invoices.clearValidators();
				break;
		}
		this.f.invoices.updateValueAndValidity();
	}

	createLabel(item: SalesReturnProcessoListaItemModel): string {
		return `${item.valor} - ${item.descricao ?? item.valor}`;
	}

	onItemAdded(process: SalesReturnProcessoModel): void {
		this.patchItems(process.listas);
		this.patchAttachments(process.anexos);
	}

	onItemEdited(process: SalesReturnProcessoModel): void {
		this.patchItems(process.listas, true);
		this.patchAttachments(process.anexos, true);
	}

	onItemRemoved(toRemove: { uuid: string; attachments: SalesReturnProcessoAnexoModel[] }): void {
		this.removeItemsByUuid(toRemove.uuid);
		this.patchAttachments(toRemove.attachments, true);
	}

	onInvoiceAdded(process: SalesReturnProcessoModel): void {
		this.patchInvoices(process.listas);
		this.patchAttachments(process.anexos);
	}

	onInvoiceEdited(process: SalesReturnProcessoModel): void {
		this.patchInvoices(process.listas, true);
		this.patchAttachments(process.anexos, true);
	}

	onInvoiceRemoved(toRemove: { uuid: string; attachments: SalesReturnProcessoAnexoModel[] }): void {
		this.removeInvoicesByUuid(toRemove.uuid);
		this.patchAttachments(toRemove.attachments, true);
	}

	patchProcess() {
		if (this.processo) {
			this.patchHeader();
			this.patchItems(this.processo.listas.filter(l => l.lista_id == this.itemListId));
			this.patchInvoices(this.processo.listas.filter(l => l.lista_id == this.invoiceListId));
			this.patchAttachments(this.processo.anexos);
		}
	}

	patchHeader() {
		const { motivo, capa } = this.processo;

		this.processForm.patchValue({
			reason: motivo,
			notes: capa[SalesReturnFieldIdType.Notes],
			requester: capa[SalesReturnFieldIdType.Requester],
		});
	}

	patchItems(items: SalesReturnProcessoListaModel[], editing = false): void {
		if (!editing) {
			this.processForm.patchValue({
				items: [...this.processForm.value.items, ...items],
			});
			return;
		}

		const [lista] = items;

		this.processForm.value.items.find((item: SalesReturnProcessoListaModel) => item.editing).lista[0] = lista.lista[0];

		this.processForm.value.items.forEach((item: SalesReturnProcessoListaModel) => {
			item.editing = false;
		});

		this.processForm.markAsDirty();
	}

	patchInvoices(invoices: SalesReturnProcessoListaModel[], editing = false): void {
		if (!editing) {
			this.processForm.patchValue({
				invoices: [...this.processForm.value.invoices, ...invoices],
			});
			return;
		}

		const [lista] = invoices;

		this.processForm.value.invoices.find((item: SalesReturnProcessoListaModel) => item.editing).lista[0] = lista.lista[0];

		this.processForm.value.invoices.forEach((item: SalesReturnProcessoListaModel) => {
			item.editing = false;
		});
	}

	patchAttachments(attachments: SalesReturnProcessoAnexoModel[], editing = false): void {
		if (!editing) {
			this.processForm.patchValue({
				attachments: [...this.processForm.value.attachments, ...attachments],
			});
			return;
		}

		// Anexos para remover
		const removed = attachments.filter(a => !a.nome);

		// Marca os anexos para remover como processados
		removed.forEach(attachment => {
			attachment.processed = true;
		});

		// Busca todos os anexos atuais menos os que foram removidos
		const current = this.processForm.value.attachments.filter(a => !removed.find(b => a.nome === b.replaceFrom && a.field === b.field && a.parent == b.parent));

		// Atualiza todos os anexos que foram editados
		current.forEach(attachment => {
			attachments.forEach(a => {
				// Busca o anexo novo com a referência do objeto
				if ((a.nome === attachment.nome || a.replaceFrom === attachment.nome) && a.field === attachment.field && a.parent == attachment.parent) {
					a.processed = true;
					this.cloneAttachment(attachment, a);
				}
			});
		});

		// Adiciona os anexos novos, que estão marcados como não processados
		attachments
			.filter(attachment => !attachment.processed)
			.forEach(attachment => {
				current.push(attachment);
			});

		this.processForm.patchValue({
			attachments: [...current],
		});
	}

	cloneAttachment(attachment: SalesReturnProcessoAnexoModel, source: SalesReturnProcessoAnexoModel): void {
		attachment.id = source.id;
		attachment.src = source.src;
		attachment.nome = source.nome;
		attachment.field = source.field;
		attachment.parent = source.parent;
		attachment.base64 = source.base64;
		attachment.usuarioInclusao = source.usuarioInclusao;
	}

	removeItemsByUuid(uuid: string): void {
		this.processForm.patchValue({
			items: this.processForm.value.items.filter(i => i.uuid !== uuid),
		});
	}

	removeInvoicesByUuid(uuid: string): void {
		this.processForm.patchValue({
			invoices: this.processForm.value.invoices.filter(i => i.uuid !== uuid),
		});
	}

	onRemoveAttachment(attachment: SalesReturnProcessoAnexoModel): void {
		this.processForm.patchValue({
			attachments: this.attachmentList.filter(a => a.nome !== attachment.nome),
		});
	}

	onAddAttachment(attachment: SalesReturnProcessoAnexoModel): void {
		this.patchAttachments([attachment]);
	}

	createProcess(): SalesReturnProcessoModel {
		if (this.isInactive) {
			return null;
		}

		const { reason, requester, notes, items, invoices, attachments } = this.processForm.value;

		const processo = new SalesReturnProcessoModel();

		processo.processo_id = this.processo?.processo_id;
		processo.fluxo_id = this.fluxo;
		processo.etapa_id = this.etapa;
		processo.motivo = reason;
		processo.cnpj = this.customer.cnpj;

		processo.capa = {
			[SalesReturnFieldIdType.Notes]: notes?.toString(),
			[SalesReturnFieldIdType.Requester]: requester?.toString(),
		};

		let itemsLista = [...items];

		if (itemsLista.length > 0) {
			const lista = new SalesReturnProcessoListaModel();
			lista.cnpj = this.customer.cnpj;
			lista.etapa_id = this.etapa;
			lista.fluxo_id = this.fluxo;
			lista.lista_id = itemsLista[0].lista_id;
			lista.lista = [[]];
			itemsLista.forEach((item: SalesReturnProcessoListaModel) => {
				const items = item.lista.flat();
				if (items.find(i => i.id == SalesReturnFieldIdType.Material)?.valor) {
					lista.lista[0].push(...items);
				}
			});
			itemsLista = [lista];
		}

		let invoicesLista = [...invoices];

		if (invoicesLista.length > 0) {
			const lista = new SalesReturnProcessoListaModel();
			lista.cnpj = this.customer.cnpj;
			lista.etapa_id = this.etapa;
			lista.fluxo_id = this.fluxo;
			lista.lista_id = invoicesLista[0].lista_id;
			lista.lista = [[]];
			invoicesLista.forEach((item: SalesReturnProcessoListaModel) => {
				const items = item.lista.flat();
				if (items.find(i => i.id == SalesReturnFieldIdType.InvoiceNumber)?.valor) {
					lista.lista[0].push(...items);
				}
			});
			invoicesLista = [lista];
		}

		processo.listas = [...itemsLista, ...invoicesLista];
		processo.anexos = attachments?.filter(a => a.base64);

		processo.listas.forEach(lista => {
			lista.cnpj = this.customer.cnpj;
		});
		return processo;
	}

	goBack(): void {
		this.routerService.goBack();
	}

	shouldAllowSave(processo: SalesReturnProcessoModel): boolean {
		if (this.isInactive) {
			return false;
		}

		if (this.etapa === SalesReturnStepType.UpToFiveRequest || this.etapa === SalesReturnStepType.MoreThanSixRequest) {
			return !processo.processo_id;
		}

		if (this.etapa === SalesReturnStepType.UpToFiveProofOfDiscard || this.etapa === SalesReturnStepType.MoreThanSixProofOfDiscard) {
			return true;
		}

		return false;
	}

	showSuccessModal(): void {
		this.modalService.open(this.successModal, { backdrop: 'static', centered: true }).result.then(
			() => {},
			() => this.redirect(),
		);
	}

	redirect(): void {
		this.router.navigate([ACCOUNT_RETURN_ROUTE_NAMES.upToFive.list.url]);
	}

	setSuccessMessage(response: any, processo: any): void {
		this.success = true;
		this.successMessage = `Solicitação número ${response?.id} ${processo?.processo_id ? 'atualizada' : 'criada'} com sucesso!`;
	}

	debugSaveProcess(event: any) {
		if (event.shiftKey) {
			event.preventDefault();
			const processo = this.createProcess();
			console.log('processo', processo);
		}
	}

	saveProcess() {
		if (this.isInactive) {
			return;
		}
		this.submitted = true;

		if (this.processForm.invalid || this.processForm.value.anexos?.length === 0) {
			return;
		}

		this.submitted = false;

		const processo = this.createProcess();

		if (!this.shouldAllowSave(processo)) {
			return;
		}

		this.salesOrderService.saveProcess(processo).subscribe({
			next: response => {
				if (response.id) {
					this.setSuccessMessage(response, processo);
					this.showSuccessModal();
					return;
				}
				this.showError(response);
			},
			error: error => {
				this.showError(error);
			},
		});
	}

	showError(ex: any): void {
		try {
			if (ex?.error?.length) {
				try {
					const error = JSON.parse(ex.error[0]);
					if (error.msg_erro) {
						this.toastService.danger(error.msg_erro);
						return;
					}
				} catch {}
				this.toastService.danger(ex.error.join('<br />'));
				return;
			}
			if (typeof ex?.error === 'string') {
				this.toastService.danger(ex.error);
				return;
			}
		} catch {}
		this.toastService.danger('Erro ao tentar salvar a solicitação, por favor tente novamente.');
	}
}
