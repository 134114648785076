import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';

@Component({
	standalone: true,
	selector: 'app-abstract-wrapper',
	imports: [CommonModule, RouterOutlet],
	template: `<router-outlet></router-outlet>`,
})
export class AbstractWrapperComponent {}
