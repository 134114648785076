import { Routes } from '@angular/router';
import { AuthGuard } from '@lib-auth/auth';

import { ACCOUNT_PATH, ACCOUNT_RETURN_ROUTE_NAMES, ACCOUNT_ROUTE_NAMES, AccountCustomersComponent, AccountInfoComponent } from '@lib-shared/modules/account';

import { SalesReturnType } from '@lib-core/enums';
import { AccountWrapperComponent } from '@proj-customer/app/modules/account/account-wrapper.component';
import { AccountSalesReturnNewComponent } from '@lib-shared/modules/account/account-sales-return/account-sales-return-new/account-sales-return-new.component';
import { AccountSalesReturnEditComponent } from '@lib-shared/modules/account/account-sales-return/account-sales-return-edit/account-sales-return-edit.component';
import { AccountSalesReturnUpToFiveComponent } from '@lib-shared/modules/account/account-sales-return/account-sales-return-up-to-five/account-sales-return-up-to-five.component';
import { AccountSalesReturnMoreThanSixComponent } from '@lib-shared/modules/account/account-sales-return/account-sales-return-more-than-six/account-sales-return-more-than-six.component';
import { CustomerGuard, IsRepresentativeGuard } from '@lib-core/guards';

export const ACCOUNT_ROUTE_LIST: Routes = [
	{
		path: ACCOUNT_PATH,
		component: AccountWrapperComponent,
		children: [
			{
				path: '',
				pathMatch: 'full',
				redirectTo: ACCOUNT_ROUTE_NAMES.info.path,
			},
			{
				path: ACCOUNT_ROUTE_NAMES.info.path,
				title: ACCOUNT_ROUTE_NAMES.info.title,
				component: AccountInfoComponent,
				canActivate: [AuthGuard],
			},
			{
				path: ACCOUNT_ROUTE_NAMES.customers.path,
				title: ACCOUNT_ROUTE_NAMES.customers.title,
				component: AccountCustomersComponent,
				canActivate: [AuthGuard, IsRepresentativeGuard],
			},
			{
				path: ACCOUNT_RETURN_ROUTE_NAMES.upToFive.path,
				title: ACCOUNT_RETURN_ROUTE_NAMES.upToFive.title,
				children: [
					{
						path: '',
						pathMatch: 'full',
						redirectTo: ACCOUNT_RETURN_ROUTE_NAMES.upToFive.list.path,
					},
					{
						path: ACCOUNT_RETURN_ROUTE_NAMES.upToFive.list.path,
						title: ACCOUNT_RETURN_ROUTE_NAMES.upToFive.list.title,
						component: AccountSalesReturnUpToFiveComponent,
						canActivate: [AuthGuard, CustomerGuard],
					},
					{
						path: ACCOUNT_RETURN_ROUTE_NAMES.upToFive.new.path,
						title: ACCOUNT_RETURN_ROUTE_NAMES.upToFive.new.title,
						component: AccountSalesReturnNewComponent,
						canActivate: [AuthGuard, CustomerGuard],
						data: {
							fluxo: SalesReturnType.UpToFive,
						},
					},
					{
						path: ACCOUNT_RETURN_ROUTE_NAMES.upToFive.edit.path,
						title: ACCOUNT_RETURN_ROUTE_NAMES.upToFive.edit.title,
						component: AccountSalesReturnEditComponent,
						canActivate: [AuthGuard, CustomerGuard],
						data: {
							fluxo: SalesReturnType.UpToFive,
						},
					},
				],
			},
			// {
			// 	path: ACCOUNT_RETURN_ROUTE_NAMES.sixOrMore.path,
			// 	title: ACCOUNT_RETURN_ROUTE_NAMES.sixOrMore.title,
			// 	children: [
			// 		{
			// 			path: '',
			// 			pathMatch: 'full',
			// 			redirectTo: ACCOUNT_RETURN_ROUTE_NAMES.sixOrMore.list.path,
			// 		},
			// 		{
			// 			path: ACCOUNT_RETURN_ROUTE_NAMES.sixOrMore.list.path,
			// 			title: ACCOUNT_RETURN_ROUTE_NAMES.sixOrMore.list.title,
			// 			component: AccountSalesReturnMoreThanSixComponent,
			// 			canActivate: [AuthGuard, CustomerGuard],
			// 		},
			// 		{
			// 			path: ACCOUNT_RETURN_ROUTE_NAMES.sixOrMore.new.path,
			// 			title: ACCOUNT_RETURN_ROUTE_NAMES.sixOrMore.new.title,
			// 			component: AccountSalesReturnNewComponent,
			// 			canActivate: [AuthGuard, CustomerGuard],
			// 			data: {
			// 				fluxo: SalesReturnType.SixOrMore,
			// 			},
			// 		},
			// 		{
			// 			path: ACCOUNT_RETURN_ROUTE_NAMES.sixOrMore.edit.path,
			// 			title: ACCOUNT_RETURN_ROUTE_NAMES.sixOrMore.edit.title,
			// 			component: AccountSalesReturnEditComponent,
			// 			canActivate: [AuthGuard, CustomerGuard],
			// 			data: {
			// 				fluxo: SalesReturnType.SixOrMore,
			// 			},
			// 		},
			// 	],
			// },
		],
	},
];
