import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet, NavigationEnd, NavigationError, NavigationStart, NavigationCancel } from '@angular/router';

import { ModeStore } from '@proj-customer/app/stores/mode.store';
import { AppModeEnum } from '@proj-customer/app/enums/app-mode.enum';
import { LoaderService } from '@lib-core/services';
import { FooterComponent } from '@lib-shared/components';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { LoaderComponent, ToastsContainer } from '@lib-core/components';

@Component({
	standalone: true,
	selector: '[app-root]',
	imports: [RouterOutlet, FooterComponent, LoaderComponent, ToastsContainer],
	providers: [NgbTooltipConfig],
	templateUrl: './app.component.html',
	styles: [
		`
			:host {
				height: 100vh;
				display: flex;
				overflow-x: hidden;
				flex-direction: column;
				main {
					flex: 1;
				}
			}
		`,
	],
})
export class AppComponent implements OnInit {
	constructor(private router: Router, private modeStore: ModeStore, private loaderService: LoaderService, private ngbTooltipConfig: NgbTooltipConfig) {
		this.tooltipConfig();
		this.modeStore.set(AppModeEnum.OnlyReturns);
	}

	ngOnInit(): void {
		this.router.events.subscribe(event => {
			switch (true) {
				case event instanceof NavigationStart:
					this.loaderService.start();
					break;
				case event instanceof NavigationEnd:
				case event instanceof NavigationError:
				case event instanceof NavigationCancel:
					this.loaderService.stop();
					break;
			}
		});
	}

	tooltipConfig(): void {
		this.ngbTooltipConfig.closeDelay = 0;
		this.ngbTooltipConfig.openDelay = 400;
	}
}
