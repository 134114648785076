import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { isStringNullOrEmpty } from '@lib-core/helpers';

export const DEFAULT_TIMEOUT = 300;

@Injectable({
	providedIn: 'root',
})
export class LoaderService {
	private timeout: any;
	private apiCount = 0;
	private messageMap: Map<string, string> = new Map<string, string>();

	private isLoadingSubject = new BehaviorSubject<boolean>(false);
	private loadingMessageSubject = new BehaviorSubject<string>('');

	isLoading$ = this.isLoadingSubject.asObservable();
	loadingMessage$ = this.loadingMessageSubject.asObservable();

	constructor() {
		this.timeout = null;
	}

	get firstMessage() {
		let message = '';

		if (this.messageMap.size === 0) {
			return message;
		}

		for (const value of this.messageMap.values()) {
			if (!isStringNullOrEmpty(value)) {
				message = value;
				break;
			}
		}

		return message;
	}

	start(url = '', message = '') {
		this.apiCount++;
		this.pushMessage(url, message);

		if (this.apiCount > 0) {
			this.isLoadingSubject.next(true);
		}
	}

	stop(url = '') {
		this.apiCount--;
		this.removeMessage(url);

		if (this.apiCount === 0 && this.messageMap.size === 0) {
			this.isLoadingSubject.next(false);
		}
	}

	pushMessage(url: string, message: string) {
		this.messageMap.set(url, message);
		this.setTimeout();
	}

	removeMessage(url: string) {
		this.messageMap.delete(url);
		this.clearTimeout();
		this.loadingMessageSubject.next(this.firstMessage);
	}

	setTimeout() {
		this.timeout = setTimeout(() => {
			this.loadingMessageSubject.next(this.firstMessage);
		}, DEFAULT_TIMEOUT);
	}

	clearTimeout() {
		this.timeout = clearTimeout(this.timeout);
	}
}
