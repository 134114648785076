import { Injectable } from '@angular/core';
import { SalesTeamModel } from '@lib-core/models';
import { BaseListStore } from '@lib-core/stores/base-list.store';
import { SALES_TEAM_COOKIE_NAME, SALES_TEAM_LIST_COOKIE_NAME } from '@lib-core/constants';

@Injectable({
	providedIn: 'root',
})
export class SalesTeamStore extends BaseListStore<SalesTeamModel> {
	constructor() {
		super(SALES_TEAM_COOKIE_NAME, SALES_TEAM_LIST_COOKIE_NAME);
	}
}
