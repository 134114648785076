export enum AppModeEnum {
	None = 0,
	Full = 1,
	OnlyReturns = 2,
}

export const AppModeMap = Object.freeze({
	[AppModeEnum.None]: '',
	[AppModeEnum.Full]: 'minha-conta',
	[AppModeEnum.OnlyReturns]: 'devolucoes',
});
