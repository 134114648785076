import { Injectable } from '@angular/core';
import { BaseStore } from '@lib-core/stores';
import { CustomerModel } from '@lib-core/models';
import { CUSTOMER_COOKIE_NAME } from '@lib-core/constants';

@Injectable({
	providedIn: 'root',
})
export class CustomerStore extends BaseStore<CustomerModel> {
	constructor() {
		super(CUSTOMER_COOKIE_NAME);
	}
}
