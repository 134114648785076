import { Injectable } from '@angular/core';
import { BaseStore } from '@lib-core/stores';
import { AppModeEnum } from '@proj-customer/app/enums/app-mode.enum';

@Injectable({
	providedIn: 'root',
})
export class ModeStore extends BaseStore<AppModeEnum> {
	constructor() {
		super('mode', false);
	}

	isFull(): boolean {
		return this.get() === AppModeEnum.Full;
	}

	isOnlyReturns(): boolean {
		return this.get() === AppModeEnum.OnlyReturns;
	}

	override isValid(): boolean {
		return this.get() !== AppModeEnum.None;
	}
}
