import { NgbModal, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormsModule, FormBuilder, ReactiveFormsModule } from '@angular/forms';

import {
	CustomerModel,
	SalesFieldModel,
	SalesReturnProcessoModel,
	SalesReturnProcessoAnexoModel,
	SalesReturnProcessoListaModel,
	SalesReturnProcessoListaItemModel,
} from '@lib-core/models';

import { CustomerStore } from '@lib-core/stores';
import { CurrencyBrlPipe } from '@lib-core/pipes';
import { SalesReturnFieldIdType } from '@lib-core/enums';
import { MULTI_SELECT_DEFAULT_CONFIG } from '@lib-core/constants';
import { fileToBase64, generateGuid } from '@lib-core/helpers';
import { SalesOrderService, SalesReturnService } from '@lib-core/services';

@Component({
	standalone: true,
	selector: 'shared-account-sales-return-items',
	imports: [CommonModule, FormsModule, ReactiveFormsModule, NgSelectModule, CurrencyBrlPipe, NgbTooltipModule],
	templateUrl: './account-sales-return-items.component.html',
})
export class AccountSalesReturnItemsComponent {
	uuid: string;
	listaId: number;
	submitted = false;

	fieldDataList: {
		sizes?: SalesFieldModel[];
		colors?: SalesFieldModel[];
		values?: SalesFieldModel[];
		failures?: SalesFieldModel[];
		invoices?: SalesFieldModel[];
		materials?: SalesFieldModel[];
		quantities?: SalesFieldModel[];
	} = {};

	item: SalesReturnProcessoListaModel;
	itemForm!: FormGroup;
	customer: CustomerModel;
	removedAttachments = new Map<SalesReturnFieldIdType, string>();
	SalesReturnFieldIdType = SalesReturnFieldIdType;

	materialDropdownSettings = {
		...MULTI_SELECT_DEFAULT_CONFIG,
		idField: 'value',
		textField: 'label',
		singleSelection: true,
		closeDropDownOnSelection: true,
	};

	@Input()
	itemList: SalesReturnProcessoListaModel[];

	@Input()
	attachmentList: SalesReturnProcessoAnexoModel[];

	@Input()
	fluxo: number;

	@Input()
	etapa: number;

	@Input()
	isActive: boolean;

	@Output()
	itemAdded = new EventEmitter<SalesReturnProcessoModel>();

	@Output()
	itemEdited = new EventEmitter<SalesReturnProcessoModel>();

	@Output()
	itemRemoved = new EventEmitter<{ uuid: string; attachments: SalesReturnProcessoAnexoModel[] }>();

	@ViewChild('itemModal')
	itemModal: ElementRef;

	@ViewChild('maxItemCount')
	maxItemCount: ElementRef;

	constructor(
		private fb: FormBuilder,
		private modalService: NgbModal,
		private customerStore: CustomerStore,
		private salesOrderService: SalesOrderService,
		private salesReturnService: SalesReturnService,
	) {
		this.customer = this.customerStore.get();
	}

	ngOnInit() {
		this.registerForm();
		this.clearDataListAndForm();
		this.listaId = this.salesReturnService.getItemListId(this.fluxo);
	}

	get f() {
		return this.itemForm.controls;
	}

	get isInactive(): boolean {
		return !this.isActive;
	}

	get isRequest(): boolean {
		return this.salesReturnService.isRequestStep(this.etapa, this.fluxo);
	}

	get isProofOfDiscard(): boolean {
		return this.salesReturnService.isProofOfDiscardStep(this.etapa, this.fluxo);
	}

	get isDone(): boolean {
		return this.salesReturnService.isDoneStep(this.etapa, this.fluxo);
	}

	get hasMaterials(): boolean {
		return this.fieldDataList.materials?.length > 0;
	}

	get hasInvoices(): boolean {
		return this.fieldDataList.invoices?.length > 0;
	}

	get hasSizes(): boolean {
		return this.fieldDataList.sizes?.length > 0;
	}

	get hasColors(): boolean {
		return this.fieldDataList.colors?.length > 0;
	}

	get hasValues(): boolean {
		return this.fieldDataList.values?.length > 0;
	}

	get hasFailures(): boolean {
		return this.fieldDataList.failures?.length > 0;
	}

	get hasQuantities(): boolean {
		return this.fieldDataList.quantities?.length > 0;
	}

	get hasItems(): boolean {
		return this.itemList?.length > 0;
	}

	get isAddOrRemoveEnable(): boolean {
		return !this.isDone && this.isActive && this.isRequest;
	}

	get isFormChanged(): boolean {
		return this.itemForm.dirty;
	}

	registerForm() {
		this.itemForm = this.fb.group({
			size: [{ value: '', disabled: true }, [Validators.required]],
			color: [{ value: '', disabled: true }, [Validators.required]],
			value: [{ value: '', disabled: true }, [Validators.required]],
			material: [{ value: '', disabled: true }, [Validators.required]],
			invoice: [{ value: '', disabled: true }, [Validators.required]],
			quantity: [{ value: '', disabled: true }, [Validators.required]],
			failure: [{ value: '', disabled: true }, [Validators.required]],
			attachment1: [null, [Validators.required]],
			attachment2: [null],
			attachment3: [null],
			discardAttachment: [{ value: null, disabled: !this.isProofOfDiscard }, [Validators.required]],
		});
	}

	createMaterialDependentField() {
		return this.salesOrderService.createSalesReturnCampoDependente(SalesReturnFieldIdType.Material, this.itemForm.value.material);
	}

	createSizeDependentField() {
		return this.salesOrderService.createSalesReturnCampoDependente(SalesReturnFieldIdType.Size, this.itemForm.value.size);
	}

	createColorDependentField() {
		return this.salesOrderService.createSalesReturnCampoDependente(SalesReturnFieldIdType.Color, this.itemForm.value.color);
	}

	createInvoiceDependentField() {
		return this.salesOrderService.createSalesReturnCampoDependente(SalesReturnFieldIdType.Invoice, this.itemForm.value.invoice);
	}

	setUuid(): void {
		this.uuid = this.item ? this.item.lista[0][0].uuid : generateGuid();
	}

	getItemFromType(type: SalesReturnFieldIdType, item: SalesReturnProcessoListaModel = null) {
		if (!item) {
			item = this.item;
		}

		if (item && item.lista?.length > 0) {
			return item.lista[0].find(i => i.id === type);
		}
		return null;
	}

	getValueFromItem(type: SalesReturnFieldIdType): string {
		return this.getItemFromType(type)?.valor ?? '';
	}

	getAttachment(type: SalesReturnFieldIdType) {
		const value = this.getItemFromType(type);

		if (value) {
			return {
				nome: value?.valor,
				parent: this.uuid,
				base64: this.getAttachmentBase64(value?.valor, type),
			};
		}

		return null;
	}

	getAttachmentBase64(filename: string, type: SalesReturnFieldIdType) {
		if (!filename || filename === '') {
			return '';
		}

		return this.attachmentList.find(a => a.nome === filename)?.base64 ?? '';
	}

	hasProofOfDiscardSaved(item: SalesReturnProcessoListaModel): boolean {
		const value = this.getItemFromType(SalesReturnFieldIdType.AttachmentProofOfDiscard, item);
		return !value ? false : !!value.usuarioInclusao;
	}

	hasProofOfDiscardSavedTooltip(item: SalesReturnProcessoListaModel): string {
		return this.hasProofOfDiscardSaved(item) ? 'Este item já possui um comprovante de descarte salvo' : '';
	}

	toggleFieldsState(force = false): void {
		if (this.isRequest || force) {
			this.f.size.enable();
			this.f.color.enable();
			this.f.value.enable();
			this.f.material.enable();
			this.f.invoice.enable();
			this.f.quantity.enable();
			this.f.failure.enable();
			return;
		}

		if (this.isProofOfDiscard) {
			this.f.size.disable();
			this.f.color.disable();
			this.f.value.disable();
			this.f.material.disable();
			this.f.invoice.disable();
			this.f.quantity.disable();
			this.f.failure.disable();
		}
	}

	loadFailures() {
		if (this.isInactive) {
			return;
		}

		this.salesOrderService.loadField(SalesReturnFieldIdType.Failure, this.customer.code).subscribe(response => {
			this.fieldDataList.failures = response;
			this.f.failure.enable();
			const value = this.getValueFromItem(SalesReturnFieldIdType.Failure);
			if (value) {
				this.f.failure.setValue(value);
			}
		});
	}

	loadMaterials() {
		if (this.isInactive) {
			return;
		}

		this.salesOrderService.loadField(SalesReturnFieldIdType.Material, this.customer.code).subscribe(response => {
			this.fieldDataList.materials = response;
			this.f.material.enable();
			const value = this.getValueFromItem(SalesReturnFieldIdType.Material);
			if (value) {
				this.f.material.setValue(value);
				this.loadInvoices();
			}
		});
	}

	loadInvoices() {
		if (this.isInactive) {
			return;
		}

		this.clearPartial(true, true, true, true, true);

		this.salesOrderService
			.loadFieldWithDependent(SalesReturnFieldIdType.Invoice, this.customer.code, [this.createMaterialDependentField()])
			.subscribe(response => {
				this.fieldDataList.invoices = response;
				this.f.invoice.enable();
				const value = this.getValueFromItem(SalesReturnFieldIdType.Invoice);
				if (value) {
					this.f.invoice.setValue(value);
					this.loadSizes();
				}
			});
	}

	loadSizes() {
		if (this.isInactive) {
			return;
		}

		this.clearPartial(false, true, true, true, true);

		this.salesOrderService
			.loadFieldWithDependent(SalesReturnFieldIdType.Size, this.customer.code, [this.createInvoiceDependentField(), this.createMaterialDependentField()])
			.subscribe(response => {
				this.fieldDataList.sizes = response;
				this.f.size.enable();
				const value = this.getValueFromItem(SalesReturnFieldIdType.Size);
				if (value) {
					this.f.size.setValue(value);
					this.loadColors();
				}
			});
	}

	loadColors() {
		if (this.isInactive) {
			return;
		}

		this.clearPartial(false, false, true, true, true);

		this.salesOrderService
			.loadFieldWithDependent(SalesReturnFieldIdType.Color, this.customer.code, [
				this.createSizeDependentField(),
				this.createInvoiceDependentField(),
				this.createMaterialDependentField(),
			])
			.subscribe(response => {
				this.fieldDataList.colors = response;
				this.f.color.enable();
				const value = this.getValueFromItem(SalesReturnFieldIdType.Color);
				if (value) {
					this.f.color.setValue(value);
					this.loadValues();
					this.loadQuantities();
				}
			});
	}

	loadQuantities() {
		if (this.isInactive) {
			return;
		}

		this.clearPartial(false, false, false, false, true);

		this.salesOrderService
			.loadFieldWithDependent(SalesReturnFieldIdType.Quantity, this.customer.code, [
				this.createColorDependentField(),
				this.createSizeDependentField(),
				this.createInvoiceDependentField(),
				this.createMaterialDependentField(),
			])
			.subscribe(response => {
				this.fieldDataList.quantities = response;
				this.f.quantity.enable();
				const value = this.getValueFromItem(SalesReturnFieldIdType.Quantity);
				if (value) {
					this.f.quantity.setValue(value);
				}
				this.toggleFieldsState();
			});
	}

	loadValues() {
		if (this.isInactive) {
			return;
		}

		this.clearPartial(false, false, false, true, false);

		this.salesOrderService
			.loadFieldWithDependent(SalesReturnFieldIdType.Value, this.customer.code, [
				this.createColorDependentField(),
				this.createSizeDependentField(),
				this.createInvoiceDependentField(),
				this.createMaterialDependentField(),
			])
			.subscribe(response => {
				this.fieldDataList.values = response;
				const value = this.getValueFromItem(SalesReturnFieldIdType.Value);
				this.f.value.enable();
				if (value) {
					this.f.value.setValue(value);
				}
				this.toggleFieldsState();
			});
	}

	loadValuesAndQuantities() {
		this.loadValues();
		this.loadQuantities();
	}

	enableAttachmentRemove(value: any) {
		return this.isAddOrRemoveEnable ? !!value?.nome : false;
	}

	removeAttachment(formPropName: string, field: SalesReturnFieldIdType) {
		this.removedAttachments.set(field, this.itemForm.value[formPropName]?.nome);
		this.itemForm.patchValue({ [formPropName]: null });
		this.itemForm.markAsDirty();
	}

	onPictureChange(event: any, propName: string, field: SalesReturnFieldIdType) {
		if (this.isInactive || !event.target?.files[0]!) {
			return;
		}

		const file = event.target.files[0];

		fileToBase64(event.target).subscribe(base64 => {
			this.itemForm.patchValue({
				[propName]: {
					nome: file.name,
					parent: this.uuid,
					base64: `data:${file.type};base64,${base64}`,
					replaceFrom: this.getAttachment(field)?.nome,
				},
			});

			this.itemForm.markAsDirty();
			event.target.value = null;
		});
	}

	checkIfAttachmentIsAlreadyInList(filename: string): boolean {
		return this.attachmentList.some(a => a.nome === filename);
	}

	clearPartial(invoices = false, sizes = false, colors = false, values = false, quantities = false) {
		if (invoices) {
			this.fieldDataList.invoices = [];
			this.itemForm.patchValue({ invoice: '' });
			this.f.invoice.disable();
		}
		if (sizes) {
			this.fieldDataList.sizes = [];
			this.itemForm.patchValue({ size: '' });
			this.f.size.disable();
		}
		if (colors) {
			this.fieldDataList.colors = [];
			this.itemForm.patchValue({ color: '' });
			this.f.color.disable();
		}
		if (values) {
			this.fieldDataList.values = [];
			this.itemForm.patchValue({ value: '' });
			this.f.value.disable();
		}
		if (quantities) {
			this.fieldDataList.quantities = [];
			this.itemForm.patchValue({ quantity: '' });
			this.f.quantity.disable();
		}
	}

	clear() {
		this.item = null;
		this.submitted = false;
		this.clearDataListAndForm();
		this.modalService.dismissAll();
	}

	clearDataListAndForm(): void {
		this.fieldDataList = {
			sizes: [],
			colors: [],
			values: [],
			failures: [],
			invoices: [],
			materials: [],
			quantities: [],
		};
		this.itemForm.patchValue({
			size: '',
			color: '',
			value: '',
			material: '',
			invoice: '',
			quantity: '',
			failure: '',
			attachment1: null,
			attachment2: null,
			attachment3: null,
			discardAttachment: null,
		});
	}

	createProcessoModel() {
		if (this.isInactive) {
			return null;
		}

		return {
			anexos: this.createProcessoAnexoModelList(),
			listas: [
				{
					uuid: this.uuid,
					lista_id: this.listaId,
					fluxo_id: this.fluxo,
					etapa_id: this.etapa,
					lista: [
						[
							this.createProcessoItemModel(this.itemForm.value.material, this.fieldDataList.materials, SalesReturnFieldIdType.Material),
							this.createProcessoItemModel(this.itemForm.value.invoice, this.fieldDataList.invoices, SalesReturnFieldIdType.Invoice),
							this.createProcessoItemModel(this.itemForm.value.size, this.fieldDataList.sizes, SalesReturnFieldIdType.Size),
							this.createProcessoItemModel(this.itemForm.value.color, this.fieldDataList.colors, SalesReturnFieldIdType.Color),
							this.createProcessoItemModel(this.itemForm.value.quantity, this.fieldDataList.quantities, SalesReturnFieldIdType.Quantity),
							this.createProcessoItemModel(this.itemForm.value.value, this.fieldDataList.values, SalesReturnFieldIdType.Value),
							this.createProcessoItemModel(this.itemForm.value.failure, this.fieldDataList.failures, SalesReturnFieldIdType.Failure),
							this.createProcessoItemModel(this.itemForm.value.attachment1?.nome, [], SalesReturnFieldIdType.Attachment1),
							this.createProcessoItemModel(this.itemForm.value.attachment2?.nome, [], SalesReturnFieldIdType.Attachment2),
							this.createProcessoItemModel(this.itemForm.value.attachment3?.nome, [], SalesReturnFieldIdType.Attachment3),
							this.createProcessoItemModel(this.itemForm.value.discardAttachment?.nome, [], SalesReturnFieldIdType.AttachmentProofOfDiscard),
						],
					],
				} as SalesReturnProcessoListaModel,
			],
		} as SalesReturnProcessoModel;
	}

	createProcessoItemModel(value: string, descriptionList: SalesFieldModel[], field: SalesReturnFieldIdType): SalesReturnProcessoListaItemModel {
		return {
			id: field,
			uuid: this.uuid,
			valor: value ?? '',
			descricao: descriptionList?.find(d => d.value === value)?.label ?? '',
		};
	}

	createProcessoAnexoModelList(onlyToRemove = false) {
		const anexos = [];

		if (this.removedAttachments.size) {
			this.removedAttachments.forEach((nome, field) => {
				if (nome) {
					anexos.push(this.createProcessoAnexoModel({ replaceFrom: nome, parent: this.uuid }, field));
				}
			});
			this.removedAttachments = new Map();
		}

		if (onlyToRemove) {
			return anexos;
		}

		anexos.push(this.createProcessoAnexoModel(this.itemForm.value.attachment1, SalesReturnFieldIdType.Attachment1));

		if (this.itemForm.value.attachment2?.nome) {
			anexos.push(this.createProcessoAnexoModel(this.itemForm.value.attachment2, SalesReturnFieldIdType.Attachment2));
		}

		if (this.itemForm.value.attachment3?.nome) {
			anexos.push(this.createProcessoAnexoModel(this.itemForm.value.attachment3, SalesReturnFieldIdType.Attachment3));
		}

		if (this.isProofOfDiscard && this.itemForm.value.discardAttachment?.nome) {
			anexos.push(this.createProcessoAnexoModel(this.itemForm.value.discardAttachment, SalesReturnFieldIdType.AttachmentProofOfDiscard));
		}

		return anexos;
	}

	isItemValid(item: SalesReturnProcessoListaModel): boolean {
		return item.lista[0].filter(i => i.valor).length > 0;
	}

	createLabel(item: SalesReturnProcessoListaModel, field: SalesReturnFieldIdType): string {
		const value = this.getItemFromType(field, item);

		if (!value) {
			return '';
		}

		if (!value.descricao || value.descricao == value.valor) {
			return value.valor;
		}

		if (field == SalesReturnFieldIdType.Failure) {
			return value.valor;
		}

		return value.descricao;
	}

	createProcessoAnexoModel(file: any, fieldId: SalesReturnFieldIdType): SalesReturnProcessoAnexoModel {
		return {
			nome: file?.nome,
			field: fieldId,
			etapa: this.etapa,
			base64: file?.base64,
			parent: file?.parent,
			replaceFrom: file?.replaceFrom,
		};
	}

	addItem() {
		if (this.isInactive) {
			return;
		}

		if (!this.isProofOfDiscard && this.itemList?.length >= 5) {
			this.modalService.open(this.maxItemCount, { centered: true }).result.then();
			return;
		}

		this.setUuid();
		this.loadMaterials();
		this.loadFailures();

		this.modalService.open(this.itemModal, { size: 'lg', backdrop: 'static' }).result.then(
			() => {},
			() => this.clear(),
		);
	}

	editItem(item: SalesReturnProcessoListaModel) {
		if (this.isInactive || this.hasProofOfDiscardSaved(item)) {
			return;
		}

		this.clearDataListAndForm();

		this.item = item;
		this.item.editing = true;
		this.setUuid();

		this.itemForm.patchValue({
			attachment1: this.getAttachment(SalesReturnFieldIdType.Attachment1),
			attachment2: this.getAttachment(SalesReturnFieldIdType.Attachment2),
			attachment3: this.getAttachment(SalesReturnFieldIdType.Attachment3),
			discardAttachment: this.getAttachment(SalesReturnFieldIdType.AttachmentProofOfDiscard),
		});

		this.addItem();
	}

	removeItem(item: SalesReturnProcessoListaModel) {
		if (this.isInactive || this.hasProofOfDiscardSaved(item)) {
			return;
		}

		this.item = item;
		this.setUuid();

		this.removedAttachments.set(SalesReturnFieldIdType.Attachment1, this.getItemFromType(SalesReturnFieldIdType.Attachment1)?.valor);
		this.removedAttachments.set(SalesReturnFieldIdType.Attachment2, this.getItemFromType(SalesReturnFieldIdType.Attachment2)?.valor);
		this.removedAttachments.set(SalesReturnFieldIdType.Attachment3, this.getItemFromType(SalesReturnFieldIdType.Attachment3)?.valor);

		this.itemRemoved.emit({
			uuid: this.uuid,
			attachments: this.createProcessoAnexoModelList(true),
		});

		this.item = null;
	}

	saveItem() {
		if (this.isInactive || !this.isFormChanged) {
			return;
		}

		this.submitted = true;

		if (this.itemForm.invalid) {
			return;
		}

		this.submitted = false;

		this.toggleFieldsState(true);

		const processo = this.createProcessoModel();

		if (this.item) {
			this.itemEdited.emit(processo);
			this.clear();
			return;
		}

		this.itemAdded.emit(processo);
		this.clear();
	}
}
