import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
	standalone: true,
	selector: 'shared-page-title',
	imports: [CommonModule],
	templateUrl: './page-title.component.html',
})
export class PageTitleComponent {
	@Input()
	title: string;

	@Input()
	showLine: boolean = true;

	@ContentChild('right')
	rightSlot: TemplateRef<any>;

	constructor(private activatedRoute: ActivatedRoute) {}

	get pageTitle(): string {
		return this.title || this.activatedRoute.snapshot.title;
	}

	get classList(): string {
		return this.rightSlot ? 'flex-md-row flex-column' : '';
	}
}
