import { Injectable } from '@angular/core';
import { RequesterType, SalesReturnStepType, SalesReturnType } from '@lib-core/enums';

const REASON_LIST = {
	[SalesReturnType.UpToFive]: [
		{ value: 2, label: 'Devolução COM emissão de nota fiscal' },
		{ value: 4, label: 'Devolução SEM emissão de nota fiscal' },
	],
	[SalesReturnType.SixOrMore]: [{ value: 3, label: 'Devolução por defeito' }],
};

const REQUESTER_LIST = [
	{ value: RequesterType.Customer, label: 'Cliente' },
	{ value: RequesterType.Representative, label: 'Representante' },
	{ value: RequesterType.CustomerRepresentative, label: 'Cliente/Representante' },
];

const REQUESTER_ITEM_LIST = {
	[SalesReturnType.UpToFive]: 5,
	[SalesReturnType.SixOrMore]: 6,
};

const REQUESTER_INVOICE_LIST = {
	[SalesReturnType.UpToFive]: 7,
	[SalesReturnType.SixOrMore]: 10,
};

@Injectable({
	providedIn: 'root',
})
export class SalesReturnService {
	isRequestStep(step: SalesReturnStepType, flow: SalesReturnType): boolean {
		switch (flow) {
			case SalesReturnType.UpToFive:
				return step === SalesReturnStepType.UpToFiveRequest;
			case SalesReturnType.SixOrMore:
				return step === SalesReturnStepType.MoreThanSixRequest;
			default:
				return false;
		}
	}

	isDoneStep(step: SalesReturnStepType, flow: SalesReturnType): boolean {
		switch (flow) {
			case SalesReturnType.UpToFive:
				return step === SalesReturnStepType.UpToFiveDone;
			case SalesReturnType.SixOrMore:
				return step === SalesReturnStepType.MoreThanSixDone;
			default:
				return false;
		}
	}

	isProofOfDiscardStep(step: SalesReturnStepType, flow: SalesReturnType): boolean {
		switch (flow) {
			case SalesReturnType.UpToFive:
				return step === SalesReturnStepType.UpToFiveProofOfDiscard;
			case SalesReturnType.SixOrMore:
				return step === SalesReturnStepType.MoreThanSixProofOfDiscard;
			default:
				return false;
		}
	}

	getReasonList(flow: SalesReturnType) {
		return REASON_LIST[flow];
	}

	getRequesterList() {
		return REQUESTER_LIST;
	}

	getRequestStep(flow: SalesReturnType) {
		return flow == SalesReturnType.UpToFive ? SalesReturnStepType.UpToFiveRequest : SalesReturnStepType.MoreThanSixRequest;
	}

	getProofOfDiscardStep(flow: SalesReturnType) {
		return flow == SalesReturnType.UpToFive ? SalesReturnStepType.UpToFiveProofOfDiscard : SalesReturnStepType.MoreThanSixProofOfDiscard;
	}

	getDoneStep(flow: SalesReturnType) {
		return flow == SalesReturnType.UpToFive ? SalesReturnStepType.UpToFiveDone : SalesReturnStepType.MoreThanSixDone;
	}

	getItemListId(flow: SalesReturnType) {
		return REQUESTER_ITEM_LIST[flow];
	}

	getInvoiceListId(flow: SalesReturnType) {
		return REQUESTER_INVOICE_LIST[flow];
	}
}
