import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { Observable, map } from 'rxjs';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';

import { CustomerStore } from '@lib-core/stores';
import { PageTitleComponent } from '@lib-shared/components';
import { SalesReturnStepType, SalesReturnType } from '@lib-core/enums';
import { CustomerModel, SalesReturnProcessoModel } from '@lib-core/models';
import { SalesOrderService, SalesReturnService } from '@lib-core/services';

import { AccountSalesReturnFormComponent } from '@lib-shared/modules/account/account-sales-return/components/account-sales-return-form/account-sales-return-form.component';

@Component({
	standalone: true,
	selector: 'shared-account-sales-return-edit',
	imports: [CommonModule, AccountSalesReturnFormComponent, NgbNavModule, PageTitleComponent, RouterLink],
	templateUrl: './account-sales-return-edit.component.html',
})
export class AccountSalesReturnEditComponent implements OnInit {
	$id: Observable<number> = this.activatedRoute.params.pipe(map(p => p['id']));

	fluxo: SalesReturnType;
	etapa: SalesReturnStepType;
	customer: CustomerModel;
	processo: SalesReturnProcessoModel;
	SalesReturnType = SalesReturnType;

	@ViewChild(AccountSalesReturnFormComponent)
	formItemComponent: AccountSalesReturnFormComponent;

	@HostListener('window:beforeunload')
	canDeactivate(): Observable<boolean> | boolean {
		return !this.formItemComponent?.isInvalid || this.formItemComponent?.success || this.formItemComponent?.processForm?.pristine;
	}

	constructor(
		private customerStore: CustomerStore,
		private activatedRoute: ActivatedRoute,
		private salesOrderService: SalesOrderService,
		private salesReturnService: SalesReturnService,
	) {
		this.fluxo = this.activatedRoute.snapshot.data?.fluxo;
		this.customer = this.customerStore.get();
	}

	ngOnInit() {
		this.initialize();
	}

	get activeTab() {
		if (this.isRequest) {
			return this.requestStep;
		}

		if (this.isProofOfDiscard) {
			return this.proofOfDiscardStep;
		}

		if (this.isDone) {
			return this.doneStep;
		}

		return 0;
	}

	get isRequest(): boolean {
		return this.salesReturnService.isRequestStep(this.etapa, this.fluxo);
	}

	get isProofOfDiscard(): boolean {
		return this.salesReturnService.isProofOfDiscardStep(this.etapa, this.fluxo);
	}

	get isDone(): boolean {
		return this.salesReturnService.isDoneStep(this.etapa, this.fluxo);
	}

	get requestStep() {
		return this.salesReturnService.getRequestStep(this.fluxo);
	}

	get proofOfDiscardStep() {
		return this.salesReturnService.getProofOfDiscardStep(this.fluxo);
	}

	get doneStep() {
		return this.salesReturnService.getDoneStep(this.fluxo);
	}

	get isProcessLoaded() {
		return !!this.processo;
	}

	get pageTitleLabel() {
		return 'Processo: ' + this.processo?.processo_id;
	}

	get isValid() {
		return !this.isInvalid;
	}

	get isInvalid() {
		return !this.processo?.processo_id;
	}

	initialize() {
		this.loadProcess();
	}

	loadProcess() {
		this.$id.subscribe(id => {
			this.salesOrderService.getReturnOrderDetails(this.customer.cnpj, id, this.fluxo).subscribe(data => {
				const processo = this.salesOrderService.parseDataToProcess(data, this.fluxo);
				if (processo) {
					this.etapa = processo.etapa_id;
					this.fluxo = processo.fluxo_id;
					this.processo = processo;
					return;
				}
				this.processo = null;
			});
		});
	}

	isValidTab(tab: number) {
		if (this.isRequest) {
			return tab == 1;
		}

		if (this.isProofOfDiscard) {
			return tab <= 2;
		}

		if (this.isDone) {
			return tab <= 3;
		}

		return false;
	}

	isTabVisible(tab: number): boolean {
		return this.isValidTab(tab) && this.activeTab >= tab;
	}
}
