import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { LoaderService } from '@lib-core/services';
import { ENVIRONMENT } from '../../../../../environments/environment';

export interface HttpResourceOptions {
	headers?: HttpHeaders;
	params?: HttpParams;
	showLoader?: boolean;
	loaderMessage?: string;
	isExternal?: boolean;
	responseType?: 'json';
}

@Injectable({
	providedIn: 'root',
})
export class HttpResource {
	constructor(private http: HttpClient, private loaderService: LoaderService) {}

	get<T>(url: string, options: HttpResourceOptions = { showLoader: true }) {
		const observable = new Subject<T>();
		this.shouldStartLoader(url, options);

		this.http
			.get<T>(`${this.getBaseUrl(options)}${url}`, {
				params: options.params,
				headers: options.headers,
			})
			.subscribe({
				next: response => this.handleObservable(observable, response, url, options),
				error: error => this.handleObservable(observable, error, url, options),
			});

		return observable;
	}

	post<T>(url: string, body: any, options: HttpResourceOptions = { showLoader: true }): Observable<T> {
		const observable = new Subject<T>();
		this.shouldStartLoader(url, options);

		this.http.post<T>(`${this.getBaseUrl(options)}${url}`, body, { ...options }).subscribe({
			next: response => this.handleObservable(observable, response, url, options),
			error: error => this.handleObservable(observable, error, url, options),
		});

		return observable;
	}

	put<T>(url: string, body: any, options: HttpResourceOptions = { showLoader: true }) {
		const observable = new Subject<T>();
		this.shouldStartLoader(url, options);

		this.http.put<T>(`${this.getBaseUrl(options)}${url}`, body, { headers: options.headers }).subscribe({
			next: response => this.handleObservable(observable, response, url, options),
			error: error => this.handleObservable(observable, error, url, options),
		});

		return observable;
	}

	delete<T>(url: string, body: any, options: HttpResourceOptions = { showLoader: true }) {
		const observable = new Subject<T>();
		this.shouldStartLoader(url, options);

		this.http.delete<T>(`${this.getBaseUrl(options)}${url}`, { body, headers: options.headers }).subscribe({
			next: response => this.handleObservable(observable, response, url, options),
			error: error => this.handleObservable(observable, error, url, options),
		});

		return observable;
	}

	private getBaseUrl(options: HttpResourceOptions): string {
		return options?.isExternal ? '' : `${ENVIRONMENT.apiSettings.url}/`;
	}

	private shouldStartLoader(url: string, options: HttpResourceOptions): void {
		if (options?.showLoader) {
			return this.loaderService.start(url, options?.loaderMessage || '');
		}
	}

	private shouldStopLoader(url: string, options: HttpResourceOptions): void {
		if (options?.showLoader) {
			this.loaderService.stop(url);
		}
	}

	private handleObservable(observable: Subject<any>, response: any, url: string, options: HttpResourceOptions) {
		observable.next(response);
		observable.complete();
		this.shouldStopLoader(url, options);
	}
}
