import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class SidebarService {
	private visible: boolean;

	constructor() {
		this.visible = false;
	}

	get isVisible() {
		return this.visible;
	}

	open() {
		this.visible = true;
	}

	close() {
		this.visible = false;
	}

	toggle() {
		this.visible = !this.visible;
	}
}
